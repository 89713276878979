.login-page {
  margin: auto;
  height: 100%;
}

.login_form {
  max-width: 600px;
  padding-top: 1rem;
  margin: auto;
}

img {
  width: 45%;
  max-width: 300px;
  margin-top: 1.5rem;
}
