.GroupPage {
  height: 100%;
}

.completed-challenges, .uncompleted-challenges, .full-challenge-list {
  width: 90%;
  margin: auto;
  padding-bottom: 1.5rem;
  max-width: 600px;
}

.challenge-list-item {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: minmax(50px, auto);
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;
  justify-items: start;
  padding-left: 1rem;
  margin: .4rem auto;
  transition: 0.3s;
  cursor: pointer;
}

.challenge-list-item:hover {
  box-shadow: 2px 1px 5px 10px dodgerblue;
}

.challenge-list-item span {
  font-weight: bold;
  color: black;
  margin-right: 10px;
}

.challenge-name {
  margin: auto 0;
}

.create_challenge_link {
  font-size: 1.2rem;
  transition: 0.2s;
}

.create_challenge_link:hover {
  font-weight: 800;
  color: blue;
}