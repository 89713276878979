@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    --primary-color: #004E64;
    --secondary-color: #00A5CF;
    --font-color: #020202;
    --light-color: #eee;
    --third-color: #7AE582;
    --error-color: #ff3138;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

input {
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    width: 70%;
    border: 1px solid #93a8f9;
    box-shadow: 2px 1px 1px #888888;
}

.input-error {
    border: 1px solid var(--error-color);
}

input:focus {
    box-shadow: 2px 1px 10px  var(--secondary-color);
}

button {
    background: var(--primary-color);
    color: var(--light-color);
    border-radius: 5px;
    border: none;
    padding: 1rem 3rem;
    margin: 0.5rem !important;
    font-size: 1.2rem !important;
    transition: 0.3s;
}

button:hover {
    background: var(--secondary-color);
    cursor: pointer;
}

.title {
    background: var(--primary-color);
    color: var(--light-color);
    padding: 1rem;
    margin-top: 0;
}

.form-error {
    color: var(--error-color);
    text-align: right;
    font-size: small;
    width: 70%;
    margin: 0 auto;
}

ul {
  list-style: none;
  padding: 0;
}

form {
  width: 80%;
  max-width: 600px;
  margin: auto;
}

a {
  color: #111111
}
@media only screen and (max-width: 600px) {
    input {
        width: 90%;
    }
}
