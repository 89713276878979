@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
* {
  box-sizing: border-box;
}

*:before,
*:after {
 box-sizing: border-box;
}

html,
body {
 height: 100%;
 position: relative;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none
}

.App {
  text-align: center;
  min-height: 100vh; 
  overflow: hidden;
  display: block;
  position: relative;
  padding-bottom: 42px; /* height of your footer */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

* {
    box-sizing: border-box;
}

:root {
    --primary-color: #004E64;
    --secondary-color: #00A5CF;
    --font-color: #020202;
    --light-color: #eee;
    --third-color: #7AE582;
    --error-color: #ff3138;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
}

input {
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    width: 70%;
    border: 1px solid #93a8f9;
    box-shadow: 2px 1px 1px #888888;
}

.input-error {
    border: 1px solid #ff3138;
    border: 1px solid var(--error-color);
}

input:focus {
    box-shadow: 2px 1px 10px  #00A5CF;
    box-shadow: 2px 1px 10px  var(--secondary-color);
}

button {
    background: #004E64;
    background: var(--primary-color);
    color: #eee;
    color: var(--light-color);
    border-radius: 5px;
    border: none;
    padding: 1rem 3rem;
    margin: 0.5rem !important;
    font-size: 1.2rem !important;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

button:hover {
    background: #00A5CF;
    background: var(--secondary-color);
    cursor: pointer;
}

.title {
    background: #004E64;
    background: var(--primary-color);
    color: #eee;
    color: var(--light-color);
    padding: 1rem;
    margin-top: 0;
}

.form-error {
    color: #ff3138;
    color: var(--error-color);
    text-align: right;
    font-size: small;
    width: 70%;
    margin: 0 auto;
}

ul {
  list-style: none;
  padding: 0;
}

form {
  width: 80%;
  max-width: 600px;
  margin: auto;
}

a {
  color: #111111
}
@media only screen and (max-width: 600px) {
    input {
        width: 90%;
    }
}

.landing_page {
    background-image: url(/static/media/huddle.44c5b574.jpg);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
    background-color: rgb(175, 172, 172, 0.2) !important;
    height: 100vh;
    color: #e4e4e4;
    text-shadow: -1px 0 #292929, 0 1px #292929, 1px 0 #292929, 0 -1px #292929;
    display: flex;
    font-size: 1.5rem;
}

.landing_page a:link{
    color: #e4e4e4;
    text-shadow: -1px 0 #4c06f0, 0 1px #292929, 1px 0 #292929, 0 -1px #292929;
}

.landing_page a:visited{
    color: #e4e4e4;
    text-shadow: -1px 0 #292929, 0 1px #292929, 1px 0 #292929, 0 -1px #292929;
}

.landing_page_text {
    margin: auto;
}

.landing_page_links {
    margin: auto;
}

.login-page {
  margin: auto;
  height: 100%;
}

.login_form {
  max-width: 600px;
  padding-top: 1rem;
  margin: auto;
}

img {
  width: 45%;
  max-width: 300px;
  margin-top: 1.5rem;
}

.register-form {
    max-width: 800px;
    margin: auto;
}

.register-page {
    height: 100%;
}
.challenge_page {
    height: 100%;
}
.create-challenge-form {
  max-width: 800px;
  margin: auto;
}

.create-challenge-form {
  padding-top: 1rem;
}


.create-group-button {
  color: #111111;
  padding: 10px 20px;
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.create-group-button:hover {
  background: dodgerblue;
  color: white;
}

.GroupList {
  padding-bottom: 1.5rem;
  max-width: 600px;
  margin: auto;
}

.GroupList div {
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;
  padding: 7px;
  margin: 5px 10px;
  cursor: pointer;
  color: #555;

}

.GroupList div span {
  font-weight: bold;
  color: black;
}

.GroupList div:hover {
  box-shadow: 2px 1px 5px 10px dodgerblue;
}

.home_page {
    height: 100%;
}
.ChallengeListItem {
  display: flex;
  flex-direction: row;
}

.ChallengeListItem_detail {
  width: 75%;
  flex-direction: row;
  flex-grow: 3
}

.ChallengeListItem_points {
  flex-grow: 1
}

.GroupPage {
  height: 100%;
}

.completed-challenges, .uncompleted-challenges, .full-challenge-list {
  width: 90%;
  margin: auto;
  padding-bottom: 1.5rem;
  max-width: 600px;
}

.challenge-list-item {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: minmax(50px, auto);
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;
  justify-items: start;
  padding-left: 1rem;
  margin: .4rem auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.challenge-list-item:hover {
  box-shadow: 2px 1px 5px 10px dodgerblue;
}

.challenge-list-item span {
  font-weight: bold;
  color: black;
  margin-right: 10px;
}

.challenge-name {
  margin: auto 0;
}

.create_challenge_link {
  font-size: 1.2rem;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.create_challenge_link:hover {
  font-weight: 800;
  color: blue;
}
.LeaderboardPage {
  width: 90%;
  margin: auto;
  padding-bottom: 1.5rem;
  max-width: 600px;
}

.leaderboard-user-item {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-auto-rows: minmax(50px, auto);
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;
  justify-items: start;
  padding-left: 1rem;
  margin: .4rem auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.leaderboard-user-item:hover {
  box-shadow: 2px 1px 5px 10px dodgerblue;
}

header {
  background-color: #7f96ea;
  padding: 5px 0;
  position: relative;
}

.navbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

header ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

header li {
  margin: 0 5px;

}

header li a {
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
  margin: 0 1rem;
}

.header_title {
  color: white;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  font-size: 2rem;
  font-weight: 675;
  margin-bottom: 10px;
}

nav ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.75rem;
  background: var(--font-color) !important;
  color: var(--light-color);
  flex-shrink: 0;
  height: 42px;
}

footer a {
  color: white;
}

