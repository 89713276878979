.create-group-button {
  color: #111111;
  padding: 10px 20px;
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.create-group-button:hover {
  background: dodgerblue;
  color: white;
}

.GroupList {
  padding-bottom: 1.5rem;
  max-width: 600px;
  margin: auto;
}

.GroupList div {
  border: 1px solid #93a8f9;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #888888;
  padding: 7px;
  margin: 5px 10px;
  cursor: pointer;
  color: #555;

}

.GroupList div span {
  font-weight: bold;
  color: black;
}

.GroupList div:hover {
  box-shadow: 2px 1px 5px 10px dodgerblue;
}
