header {
  background-color: #7f96ea;
  padding: 5px 0;
  position: relative;
}

.navbar {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

header ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

header li {
  margin: 0 5px;

}

header li a {
  font-size: 1.2rem;
  cursor: pointer;
  color: white;
  margin: 0 1rem;
}

.header_title {
  color: white;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 2rem;
  font-weight: 675;
  margin-bottom: 10px;
}

nav ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0;
}
