footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.75rem;
  background: var(--font-color) !important;
  color: var(--light-color);
  flex-shrink: 0;
  height: 42px;
}

footer a {
  color: white;
}
